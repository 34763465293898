<div
  *transloco="let t; read: 'shared.statusBar'"
  [matTooltip]="t('hints.notAvailableForRfq')"
  [matTooltipDisabled]="tooltipDisabled()"
>
  <button
    mat-flat-button
    color="primary"
    class="!mr-4"
    [disabled]="buttonDisabled()"
    [routerLink]="['/detail', 'bom']"
    queryParamsHandling="merge"
    (click)="loadBom()"
  >
    {{ t('loadBom') }}
  </button>
</div>
