<ng-container *ngrxLet="selectedNodeIds$; let nodeIds">
  <div
    *transloco="let t; read: 'shared.statusBar'"
    [matTooltip]="
      t(nodeIds?.length < minCount ? 'hints.minCount' : 'hints.maxCount', {
        count: nodeIds?.length < minCount ? minCount : maxCount
      })
    "
    [matTooltipPosition]="'above'"
    [matTooltipDisabled]="
      (nodeIds?.length >= minCount && nodeIds?.length <= maxCount) || disabled
    "
    class="inline-block"
  >
    <button
      mat-flat-button
      color="primary"
      class="!mr-4"
      [disabled]="
        nodeIds?.length < minCount || nodeIds?.length > maxCount || disabled
      "
      (click)="showCompareView(nodeIds)"
    >
      {{ t('compare') }}
    </button>
  </div>
</ng-container>
