<div
  *transloco="let t; read: 'userInteraction.feature.bomExport'"
  class="content"
>
  @if (export.status?.exportType === typePcm) {
    <div class="pcm">
      <p>{{ t('pcm.header') }}</p>
      <form class="w-full min-h-[2rem] max-h-[12rem]">
        <mat-form-field class="w-full">
          <textarea
            class="lock-resize"
            type="text"
            matInput
            [formControl]="pcmFormControl"
            [errorStateMatcher]="pcmErrorStateMatcher"
            placeholder="{{ t('pcm.placeholder') }}"
          ></textarea>
          <mat-hint>{{ t('pcm.hint') }}</mat-hint>
          @if (!pcmFormControl.valid) {
            <mat-error class="text-caption">{{
              pcmValidationErrorMsg
            }}</mat-error>
          }
        </mat-form-field>
      </form>
      <button
        mat-flat-button
        color="primary"
        (click)="onPcmBomExportStart()"
        [disabled]="isPcmBomExportStartDisabled()"
      >
        {{ t('pcm.startBomExport') }}
      </button>
    </div>
  } @else {
    <div class="h-full"></div>
  }
  <div class="title">
    <p>{{ t('common.title', { exportType: export.status?.exportType }) }}</p>
    <div
      [matTooltip]="t('tooltip.refreshStatus')"
      [matTooltipPosition]="'above'"
    >
      <button
        mat-icon-button
        [disabled]="refreshStatusBtnDisabled"
        (click)="refreshProgress(export.status.exportType)"
      >
        <mat-icon>refresh</mat-icon>
      </button>
    </div>
  </div>
  <div>
    <p>
      {{ t('common.currentProgress', { progress: userFriendlyProgress }) }}
    </p>
    <mat-progress-bar
      [mode]="'determinate'"
      [value]="progressBarValue"
    ></mat-progress-bar>
  </div>
  <div>
    <p>
      {{
        t('common.started', {
          dateTime:
            (export.status?.started
            | localizedOffsetDatetime
            | undefinedAttributeFallback)
        })
      }}
    </p>
    <p>
      {{
        t('common.stopped', {
          dateTime:
            (export.status?.stopped
            | localizedOffsetDatetime
            | undefinedAttributeFallback)
        })
      }}
    </p>
  </div>
  <div class="download-expiry">
    @if (export.status?.progress === 'FINISHED') {
      <p>
        {{
          t('common.downloadExpires', {
            dateTime:
              (export.status?.downloadUrlExpiry
              | localizedOffsetDatetime
              | undefinedAttributeFallback)
          })
        }}
      </p>
    }
  </div>
  <div
    class="download-button"
    [matTooltip]="downloadBtnTooltip"
    [matTooltipDisabled]="isDownloadBtnEnabled()"
    [matTooltipPosition]="'above'"
  >
    <button
      mat-flat-button
      color="primary"
      [disabled]="!isDownloadBtnEnabled()"
      (click)="onDownload()"
    >
      {{ t('common.download') }}
    </button>
  </div>
</div>
