<div
  class="flex h-16 flex-col justify-evenly text-caption font-normal text-high-emphasis"
  *transloco="let t; read: 'shared.bom.statusBar'"
>
  <span>{{ t('title') }}</span>
  <div class="grid h-3 grid-cols-6 items-stretch">
    <div class="bg-secondary-legacy-800 h-3 rounded-l-md"></div>
    <div class="bg-secondary-legacy-600 h-3"></div>
    <div class="bg-secondary-legacy-400 h-3"></div>
    <div class="bg-secondary-legacy-200 h-3"></div>
    <div class="bg-secondary-legacy-50 h-3"></div>
    <div class="bg-light-red h-3 rounded-r-md"></div>
  </div>
  <div class="grid h-3 grid-cols-6">
    <div class="place-self-center">&#x3E;50%</div>
    <div class="place-self-center">50-30%</div>
    <div class="place-self-center">30-20%</div>
    <div class="place-self-center">20-10%</div>
    <div class="place-self-center">10-0%</div>
    <div class="place-self-center">&#x3C;0%</div>
  </div>
</div>
