import { createSelector } from '@ngrx/store';

import { BomExportType } from '@cdba/user-interaction/model/feature/bom-export/bom-export-status.model';

import { BomExportProgress } from '../../../../user-interaction/model/feature/bom-export';
import { getUserInteractionState } from '../../reducers';
import {
  BomExportFeature,
  UserInteractionState,
} from '../../reducers/user-interaction/user-interaction.reducer';

export const getBomExportFeatures = createSelector(
  getUserInteractionState,
  (state: UserInteractionState) => [
    state.feature.sapBomExport,
    state.feature.pcmBomExport,
  ]
);

export const getBomExportFeature = (props: { exportType: BomExportType }) =>
  createSelector(getUserInteractionState, (state: UserInteractionState) => {
    switch (props.exportType) {
      case BomExportType.SAP: {
        return state.feature.sapBomExport;
      }
      case BomExportType.PCM: {
        return state.feature.pcmBomExport;
      }
      default: {
        throw new Error(
          `Unhandled BomExportType: ${props.exportType} passed to selector`
        );
      }
    }
  });

export const getBomExportFeatureStatus = (props: {
  exportType: BomExportType;
}) =>
  createSelector(
    getBomExportFeature(props),
    (feature: BomExportFeature) => feature.status
  );

export const isBomExportFeatureRunning = (props: {
  exportType: BomExportType;
}) =>
  createSelector(getBomExportFeature(props), (feature: BomExportFeature) => {
    if (feature.status) {
      return (
        feature.status.progress !== BomExportProgress.FINISHED &&
        feature.status.progress !== BomExportProgress.FAILED
      );
    }

    return false;
  });
