<div *transloco="let t; read: 'userInteraction.dialog'">
  <div mat-dialog-title class="dialog-title">
    <p>
      {{ t('welcome', { username: (username$ | ngrxPush) }) }}
    </p>
    <button mat-icon-button mat-dialog-close disableRipple (click)="onClose()">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <mat-dialog-content>
    <div class="dialog-content">
      <div class="dialog-content-feature">
        <p>{{ t('bomExport') }}</p>
        <div
          [matTooltip]="t('tooltip.refreshStatus')"
          [matTooltipPosition]="'after'"
        >
          <button
            mat-icon-button
            [disabled]="refreshStatusBtnDisabled"
            (click)="refreshProgress()"
          >
            <mat-icon>refresh</mat-icon>
          </button>
        </div>
      </div>
      <div>
        <p>
          {{ t('currentProgress', { progress: userFriendlyProgress }) }}
        </p>
        <mat-progress-bar
          [mode]="'buffer'"
          [value]="progressBarValue"
        ></mat-progress-bar>
      </div>
      <div>
        <p>
          {{
            t('started', {
              dateTime:
                (status?.started
                | localizedOffsetDatetime
                | undefinedAttributeFallback)
            })
          }}
        </p>
        <p>
          {{
            t('stopped', {
              dateTime:
                (status?.stopped
                | localizedOffsetDatetime
                | undefinedAttributeFallback)
            })
          }}
        </p>
      </div>
      <div class="dialog-content-download-expiry">
        @if (status?.progress === 'FINISHED') {
          <p>
            {{
              t('downloadExpires', {
                dateTime:
                  (status?.downloadUrlExpiry
                  | localizedOffsetDatetime
                  | undefinedAttributeFallback)
              })
            }}
          </p>
        }
      </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <div
      [matTooltip]="downloadBtnTooltip"
      [matTooltipDisabled]="!isDownloadBtnDisabled()"
      [matTooltipPosition]="'above'"
    >
      <button
        mat-flat-button
        color="primary"
        class="!mr-4"
        [disabled]="isDownloadBtnDisabled()"
        (click)="onDownload()"
      >
        {{ t('download') }}
      </button>
    </div>
  </mat-dialog-actions>
</div>
