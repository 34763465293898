<div *transloco="let t">
  @if (showHeading) {
    <div class="mb-4 text-subtitle-1 text-medium-emphasis">
      {{ headingText || t('rightsAndRolesHeadingDefault') }}
    </div>
  }
  @if (rolesGroups && rolesGroups.length) {
    <schaeffler-roles-groups
      [rolesGroups]="rolesGroups"
    ></schaeffler-roles-groups>
  }
  @if (roles && roles.length) {
    <schaeffler-roles [roles]="roles" [standalone]="true"></schaeffler-roles>
  }
</div>
