<div *transloco="let t; read: 'userInteraction'">
  <div mat-dialog-title class="dialog-title">
    <p>
      {{ t('tooltip.dialog.welcome') + ' ' + (username$ | ngrxPush) + ' 👋🏻' }}
    </p>
    <button mat-icon-button mat-dialog-close disableRipple>
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <mat-dialog-content>
    <div class="dialog-content">
      <div class="dialog-content-feature">
        <p>{{ t('tooltip.dialog.bomExport') }}</p>
        <div
          [matTooltip]="t('tooltip.dialog.refreshProgressBtn')"
          [matTooltipPosition]="'after'"
        >
          <button
            mat-icon-button
            [disabled]="refreshProgressBtnDisabled"
            (click)="refreshProgress()"
          >
            <mat-icon>refresh</mat-icon>
          </button>
        </div>
      </div>
      <div>
        <p>
          {{
            t('tooltip.dialog.currentProgress') + ' : ' + userFriendlyProgress
          }}
        </p>
        <mat-progress-bar
          [mode]="'buffer'"
          [value]="progressBarValue"
        ></mat-progress-bar>
      </div>
      <div>
        <p>
          {{
            t('tooltip.dialog.started') +
              ' : ' +
              (status?.started | date: 'medium' | undefinedAttributeFallback)
          }}
        </p>
        <p>
          {{
            t('tooltip.dialog.stopped') +
              ' : ' +
              (status?.stopped | date: 'medium' | undefinedAttributeFallback)
          }}
        </p>
      </div>
      <div class="dialog-content-download-expiry">
        @if (status?.progress === 'FINISHED') {
          <p>
            {{
              t('tooltip.dialog.downloadExpires') +
                ' : ' +
                (status?.downloadUrlExpiry
                  | date: 'medium'
                  | undefinedAttributeFallback)
            }}
          </p>
        }
      </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button
      mat-flat-button
      color="primary"
      class="!mr-4"
      [disabled]="status?.progress !== 'FINISHED'"
      (click)="onDownload()"
    >
      {{ t('tooltip.dialog.download') }}
    </button>
  </mat-dialog-actions>
</div>
