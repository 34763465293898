<div *transloco="let t; read: 'roles.descriptions'">
  @if (rolesGroups && rolesGroups.length) {
    <schaeffler-roles-and-rights
      [rolesGroups]="rolesGroups"
      [showHeading]="showHeading"
    ></schaeffler-roles-and-rights>
  }
  <div
    class="whitespace-pre-line pt-4 text-body-1"
    [innerHTML]="
      t('hints.' + hintText, {
        urlServiceNow,
        urlRoleAssignments
      })
    "
  ></div>
</div>
