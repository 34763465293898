export const URL_FAQ = `https://worksite.sharepoint.com/sites/Digitalization-and-IT/SitePages/FAQ(2).aspx`;
export const URL_MY_SCHAEFFLER = `https://worksite.sharepoint.com/sites/Digitalization-and-IT/SitePages/Cost-Database-Analytics.aspx`;
export const URL_SERVICE_NOW = `https://schaefflerprod.service-now.com/sup?id=sc_cat_item&sys_id=2d1e91cfdb5ba20038c2b6bffe961953&sysparm_category=19634e32dbb73e00d624b14ffe961977`;
export const URL_ROLE_ASSIGNMENTS = `https://worksite.sharepoint.com/sites/Digitalization-and-IT/SitePages/Rights-%26-Roles.aspx`;
export const URL_SAMS = `https://webhp1.schaeffler.com:62839/sap/bc/webdynpro/SGD/bc_sams_ep_wda?sap-client=100`;

export const URL_SUPPORT = `https://schaefflerprod.service-now.com/sup`;

export const AUTH_URLS: string[] = [
  'https://login.microsoftonline',
  'https://login.partner.microsoftonline',
  'https://graph.microsoft.com',
];
