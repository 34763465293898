import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable, take } from 'rxjs';

import { fetchEventSource } from '@microsoft/fetch-event-source';
import { Store } from '@ngrx/store';

import { getAccessToken } from '@schaeffler/azure-auth';

import {
  trackBomExportStatusCompleted,
  trackBomExportStatusFailure,
  updateBomExportStatus,
} from '@cdba/core/store';
import {
  API,
  BomExportStatusesPath,
  BomExportStatusLivePath,
  PcmBomExportPath,
  SapBomExportPath,
} from '@cdba/shared/constants/api';
import { ReferenceTypeIdentifier } from '@cdba/shared/models';
import {
  BomExportProgress,
  BomExportStatus,
} from '@cdba/user-interaction/model/feature/bom-export';
import { BomExportType } from '@cdba/user-interaction/model/feature/bom-export/bom-export-status.model';

@Injectable({ providedIn: 'root' })
export class BomExportService {
  constructor(
    private readonly httpClient: HttpClient,
    private readonly store: Store
  ) {}

  /**
   * Loads the initial BOM (Bill of Materials) export statuses.
   *
   * @returns An Observable that emits the current BOM export statuses for requesting user.
   */
  loadInitialBomExportStatuses(): Observable<BomExportStatus[]> {
    return this.httpClient.get<BomExportStatus[]>(
      `${API.v1}/${BomExportStatusesPath}`
    );
  }

  requestSapBomExport(
    identifiers: ReferenceTypeIdentifier[]
  ): Observable<HttpResponse<void>> {
    const path = `${API.v1}/${SapBomExportPath}`;

    return this.httpClient.post<void>(path, identifiers, {
      observe: 'response',
    });
  }

  requestPcmBomExport(identifiers: string[]): Observable<HttpResponse<void>> {
    const path = `${API.v1}/${PcmBomExportPath}`;

    return this.httpClient.post<void>(path, identifiers, {
      observe: 'response',
    });
  }

  trackBomExportStatus(exportType: BomExportType): void {
    let progress: BomExportProgress;
    let token: string;
    this.store
      .select(getAccessToken)
      .pipe(take(1))
      .subscribe((accessToken) => {
        token = accessToken;
      });

    fetchEventSource(
      `${API.v1}/${BomExportStatusLivePath}` + `?exportType=${exportType}`,
      {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
        keepalive: true,
        cache: 'no-cache',
        onmessage: (message) => {
          const currentStatus = JSON.parse(message.data);
          progress = (currentStatus as BomExportStatus).progress;
          this.store.dispatch(updateBomExportStatus({ currentStatus }));
        },
        onclose: () => {
          this.handleClose(exportType, progress);
        },
        onerror: (error) => {
          this.handleError(exportType, error);
        },
      }
    );
  }

  private handleClose(exportType: BomExportType, progress: BomExportProgress) {
    if (
      progress === BomExportProgress.FINISHED ||
      progress === BomExportProgress.FAILED
    ) {
      this.store.dispatch(trackBomExportStatusCompleted({ exportType }));
    } else {
      this.store.dispatch(
        trackBomExportStatusFailure({
          exportType,
          errorMessage: 'Wrong progress when closing listener',
        })
      );
    }
  }

  private handleError(exportType: BomExportType, error: any) {
    this.store.dispatch(
      trackBomExportStatusFailure({
        exportType,
        errorMessage: error.message,
      })
    );
  }
}
