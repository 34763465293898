<div *transloco="let t; read: 'shared.bom.additionalInformation.costElements'">
  @if (currentSplitType) {
    <button
      mat-button
      color="primary"
      class="!mr-4"
      queryParamsHandling="merge"
      (click)="toggleSplitType.emit()"
    >
      {{ currentSplitType === 'MAIN' ? t('showMore') : t('showLess') }}
    </button>
  }
</div>
