<div *transloco="let t; read: 'userInteraction.dialog'">
  <div mat-dialog-title class="dialog-title">
    <h6>
      {{ t('welcome', { username: (username$ | ngrxPush) }) }}
    </h6>
    <button mat-icon-button mat-dialog-close disableRipple (click)="onClose()">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <mat-dialog-content>
    <div class="dialog-content">
      @for (export of exports; track export) {
        <cdba-bom-export [export]="export"></cdba-bom-export>
      } @empty {
        <h5>{{ t('noFeatures') }}</h5>
      }
    </div>
  </mat-dialog-content>
</div>
