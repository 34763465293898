@if (show) {
  <div class="progress-spinner-wrapper">
    <mat-progress-spinner
      color="primary"
      mode="indeterminate"
      diameter="40"
      value="50"
      class="progress-spinner"
    >
    </mat-progress-spinner>
  </div>
}
