export enum InteractionType {
  HTTP_GENERAL_ERROR,
  HTTP_GENERAL_VALIDATION_ERROR,
  REQUEST_BOM_EXPORT_SUCCESS,
  REQUEST_BOM_EXPORT_VALIDATION_ERROR,
  REQUEST_BOM_EXPORT_FAILURE,
  TRACK_BOM_EXPORT_PROGRESS_SUCCESS,
  TRACK_BOM_EXPORT_PROGRESS_FAILURE,
  GET_BOM_EXPORT_STATUS_FAILURE,
}
