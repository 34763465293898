import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { catchError, map, mergeMap, of, take, tap } from 'rxjs';

import { Actions, createEffect, ofType, OnInitEffects } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';

import { BomExportService } from '@cdba/shared/services/bom-export/bom-export.service';
import { BomExportStatus } from '@cdba/user-interaction/model/feature/bom-export';
import { BomExportType } from '@cdba/user-interaction/model/feature/bom-export/bom-export-status.model';
import { BOM_EXPORT_RUNNING } from '@cdba/user-interaction/model/feature/bom-export/bom-export-status-enum.model';
import { InteractionType } from '@cdba/user-interaction/model/interaction-type.enum';
import { UserInteractionService } from '@cdba/user-interaction/service/user-interaction.service';

import {
  loadInitialBomExportStatuses,
  loadInitialBomExportStatusesFailure,
  loadInitialBomExportStatusesSuccess,
  requestPcmBomExport,
  requestPcmBomExportFailure,
  requestPcmBomExportSuccess,
  requestSapBomExport,
  requestSapBomExportFailure,
  requestSapBomExportSuccess,
  showSnackBar,
  trackBomExportStatus,
  trackBomExportStatusCompleted,
  trackBomExportStatusFailure,
} from '../../actions';
import { BomExportFeature } from '../../reducers/user-interaction/user-interaction.reducer';
import { getBomExportFeatures } from '../../selectors';

/**
 * Effects class for all effects which trigger interaction with the user
 */
@Injectable()
export class UserInteractionEffects implements OnInitEffects {
  // Section: User interaction without SnackBar
  trackBomExportStatus$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(trackBomExportStatus),
        tap((action) =>
          this.bomExportService.trackBomExportStatus(action.exportType)
        )
      );
    },
    { dispatch: false }
  );

  // Section: General user interaction by only showing a SnackBar
  showSnackBar$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(showSnackBar),
        tap((action) => {
          this.userInteractionService.interact(action.interactionType);
        })
      );
    },
    { dispatch: false }
  );

  trackBomExportStatusCompleted$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(trackBomExportStatusCompleted),
      map(() =>
        showSnackBar({
          interactionType: InteractionType.TRACK_BOM_EXPORT_PROGRESS_COMPLETED,
        })
      )
    );
  });

  trackBomExportStatusFailure$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(trackBomExportStatusFailure),
      map(() =>
        showSnackBar({
          interactionType: InteractionType.TRACK_BOM_EXPORT_PROGRESS_FAILURE,
        })
      )
    );
  });

  // Section: specific use cases where additional actions should be dispatched
  requestSapBomExport$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(requestSapBomExport),
      map((action) => action.identifiers),
      mergeMap((identifiers) =>
        this.bomExportService.requestSapBomExport(identifiers).pipe(
          mergeMap(() => of(requestSapBomExportSuccess())),
          catchError((error: HttpErrorResponse) =>
            of(
              requestSapBomExportFailure({
                statusCode: error.status,
                errorMessage: error.message,
              })
            )
          )
        )
      )
    );
  });

  requestSapBomExportSuccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(requestSapBomExportSuccess),
      mergeMap(() => [
        showSnackBar({
          interactionType: InteractionType.REQUEST_BOM_EXPORT_SUCCESS,
        }),
        trackBomExportStatus({ exportType: BomExportType.SAP }),
      ])
    );
  });

  requestSapBomExportFailure$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(requestSapBomExportFailure),
      map((action) => {
        return action.statusCode === HttpStatusCode.BadRequest
          ? showSnackBar({
              interactionType:
                InteractionType.REQUEST_BOM_EXPORT_VALIDATION_ERROR,
            })
          : showSnackBar({
              interactionType: InteractionType.REQUEST_BOM_EXPORT_FAILURE,
            });
      })
    );
  });

  requestPcmBomExport$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(requestPcmBomExport),
      map((action) => action.identifiers),
      mergeMap((identifiers) =>
        this.bomExportService.requestPcmBomExport(identifiers).pipe(
          mergeMap(() => of(requestPcmBomExportSuccess())),
          catchError((error: HttpErrorResponse) =>
            of(
              requestPcmBomExportFailure({
                statusCode: error.status,
                errorMessage: error.message,
              })
            )
          )
        )
      )
    );
  });

  requestPcmBomExportSuccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(requestPcmBomExportSuccess),
      mergeMap(() => [
        showSnackBar({
          interactionType: InteractionType.REQUEST_BOM_EXPORT_SUCCESS,
        }),
        trackBomExportStatus({ exportType: BomExportType.PCM }),
      ])
    );
  });

  requestPcmBomExportFailure$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(requestPcmBomExportFailure),
      map((action) => {
        return action.statusCode === HttpStatusCode.BadRequest
          ? showSnackBar({
              interactionType:
                InteractionType.REQUEST_BOM_EXPORT_VALIDATION_ERROR,
            })
          : showSnackBar({
              interactionType: InteractionType.REQUEST_BOM_EXPORT_FAILURE,
            });
      })
    );
  });

  loadInitialBomExportStatuses$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(loadInitialBomExportStatuses),
      mergeMap(() =>
        this.bomExportService.loadInitialBomExportStatuses().pipe(
          mergeMap((statuses: BomExportStatus[]) => {
            const actions: any[] = [
              loadInitialBomExportStatusesSuccess({ statuses }),
            ];

            if (
              statuses.some((status) =>
                BOM_EXPORT_RUNNING.includes(status.progress)
              )
            ) {
              statuses.forEach((status) => {
                if (status.exportType === BomExportType.SAP) {
                  actions.push(
                    trackBomExportStatus({ exportType: BomExportType.SAP })
                  );
                }
                if (status.exportType === BomExportType.PCM) {
                  actions.push(
                    trackBomExportStatus({ exportType: BomExportType.PCM })
                  );
                }
              });
            }

            return actions; // Use from to emit each action individually
          }),
          catchError((error: HttpErrorResponse) => {
            // Ignore Http404 - it means that the user never requested bom export
            return error.status === HttpStatusCode.NotFound
              ? of(loadInitialBomExportStatusesSuccess({ statuses: undefined }))
              : of(
                  showSnackBar({
                    interactionType:
                      InteractionType.GET_BOM_EXPORT_STATUS_FAILURE,
                  }),
                  loadInitialBomExportStatusesFailure({
                    errorMessage: error.message,
                  })
                );
          })
        )
      )
    );
  });

  constructor(
    private readonly actions$: Actions,
    private readonly store: Store,
    private readonly userInteractionService: UserInteractionService,
    private readonly bomExportService: BomExportService
  ) {}

  ngrxOnInitEffects(): Action {
    this.store
      .select(getBomExportFeatures)
      .pipe(
        take(1),
        tap((features: BomExportFeature[]) => {
          if (features.some((feature) => !feature.status.progress)) {
            this.store.dispatch(loadInitialBomExportStatuses());
          }
        })
      )
      .subscribe();

    return { type: 'NO_ACTION' } as Action;
  }
}
