import { CommonModule } from '@angular/common';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import {
  FormControl,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatTooltipModule } from '@angular/material/tooltip';

import { Subscription } from 'rxjs';

import { TranslocoService } from '@jsverse/transloco';
import { Store } from '@ngrx/store';

import { LoadingSpinnerModule } from '@schaeffler/loading-spinner';
import { SharedTranslocoModule } from '@schaeffler/transloco';

import {
  requestPcmBomExport,
  resetPcmBomExportStatusTracking,
  resetSapBomExportStatusTracking,
  trackBomExportStatus,
} from '@cdba/core/store';
import { BomExportFeature } from '@cdba/core/store/reducers/user-interaction/user-interaction.reducer';
import {
  LocalizedOffsetDatetimePipe,
  UndefinedAttributeFallbackModule,
} from '@cdba/shared/pipes';
import {
  BomExportProgress,
  BomExportStatus,
} from '@cdba/user-interaction/model/feature/bom-export';
import { BomExportType } from '@cdba/user-interaction/model/feature/bom-export/bom-export-status.model';
import { BOM_EXPORT_RUNNING } from '@cdba/user-interaction/model/feature/bom-export/bom-export-status-enum.model';

import { CustomErrorStateMatcher } from './validation/custom-error-state-matcher';

@Component({
  selector: 'cdba-bom-export',
  standalone: true,
  imports: [
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    CommonModule,
    MatIconModule,
    MatButtonModule,
    MatTooltipModule,
    MatProgressBarModule,
    SharedTranslocoModule,
    UndefinedAttributeFallbackModule,
    LocalizedOffsetDatetimePipe,
    LoadingSpinnerModule,
  ],
  templateUrl: './bom-export.component.html',
  styleUrl: './bom-export.component.scss',
})
export class BomExportComponent implements OnInit, OnDestroy {
  @Input()
  export: BomExportFeature;

  userFriendlyProgress: string;
  progressBarValue = 0;
  refreshStatusBtnDisabled = true;
  downloadBtnTooltip: string;

  typePcm = BomExportType.PCM;
  pcmValidationErrorMsg: string;

  formControlValueChangedSubscription: Subscription;
  pcmFormControl = new FormControl('', [
    Validators.required,
    Validators.pattern(/^(\d{8})(,\d{8})*$/),
    Validators.maxLength(44),
  ]);

  pcmErrorStateMatcher = new CustomErrorStateMatcher();

  constructor(
    private readonly store: Store,
    private readonly transloco: TranslocoService
  ) {}

  ngOnInit(): void {
    this.updateTranslations(this.export.status);
    this.updateProgress(this.export.status);
    if (this.export.status?.progress === BomExportProgress.FAILED) {
      this.refreshStatusBtnDisabled = false;
    }

    this.formControlValueChangedSubscription =
      this.pcmFormControl.valueChanges.subscribe(() => {
        this.validatePcmFormControl();
      });
  }

  ngOnDestroy(): void {
    if (this.formControlValueChangedSubscription) {
      this.formControlValueChangedSubscription.unsubscribe();
    }
  }

  onDownload(): void {
    if (this.export.status?.downloadUrl) {
      window.open(this.export.status.downloadUrl, '_blank');
    }
  }

  isDownloadBtnEnabled(): boolean {
    return (
      this.export.status?.progress === BomExportProgress.FINISHED &&
      new Date(this.export.status?.downloadUrlExpiry) > new Date()
    );
  }

  /**
   * Manually refresh progress tracking in case of a tracking error
   */
  refreshProgress(exportType: BomExportType): void {
    switch (exportType) {
      case BomExportType.SAP: {
        this.store.dispatch(resetSapBomExportStatusTracking());
        this.store.dispatch(
          trackBomExportStatus({ exportType: BomExportType.SAP })
        );
        break;
      }
      case BomExportType.PCM: {
        this.store.dispatch(resetPcmBomExportStatusTracking());
        this.store.dispatch(
          trackBomExportStatus({ exportType: BomExportType.PCM })
        );
        break;
      }
      default: {
        throw new Error(`Unhandled BomExportType: ${exportType}`);
      }
    }
  }

  validatePcmFormControl(): void {
    if (this.pcmFormControl.hasError('required')) {
      this.pcmValidationErrorMsg = this.transloco.translate(
        'userInteraction.feature.bomExport.pcm.validation.required'
      );
    } else if (
      !this.pcmFormControl.hasError('maxlength') &&
      this.pcmFormControl.hasError('pattern')
    ) {
      this.pcmValidationErrorMsg = this.transloco.translate(
        'userInteraction.feature.bomExport.pcm.validation.pattern'
      );
    } else if (
      !this.pcmFormControl.hasError('pattern') &&
      this.pcmFormControl.hasError('maxlength')
    ) {
      this.pcmValidationErrorMsg = this.transloco.translate(
        'userInteraction.feature.bomExport.pcm.validation.maxLength'
      );
    }
  }

  isPcmBomExportStartDisabled(): boolean {
    return (
      this.pcmFormControl.invalid ||
      BOM_EXPORT_RUNNING.includes(this.export.status.progress)
    );
  }

  onPcmBomExportStart(): void {
    const identifiers: string[] = [];
    this.pcmFormControl.value?.split(',').forEach((identifier) => {
      identifiers.push(identifier.trim());
    });

    if (identifiers.length > 0) {
      this.store.dispatch(
        requestPcmBomExport({
          identifiers,
        })
      );
    }
  }

  private updateTranslations(status: BomExportStatus): void {
    if (BOM_EXPORT_RUNNING.includes(status?.progress)) {
      this.userFriendlyProgress = this.transloco.translate(
        'userInteraction.feature.bomExport.common.progress.inProgress'
      );
    } else if (status?.progress === undefined) {
      this.userFriendlyProgress = this.transloco.translate(
        'userInteraction.feature.bomExport.common.progress.waiting'
      );
    } else {
      switch (status?.progress) {
        case BomExportProgress.STARTED: {
          this.userFriendlyProgress = this.transloco.translate(
            'userInteraction.feature.bomExport.common.progress.started'
          );

          break;
        }
        case BomExportProgress.FAILED: {
          this.userFriendlyProgress = this.transloco.translate(
            'userInteraction.feature.bomExport.common.progress.failed'
          );
          this.downloadBtnTooltip = this.transloco.translate(
            'userInteraction.feature.bomExport.tooltip.exportFailed'
          );

          break;
        }
        case BomExportProgress.FINISHED: {
          this.userFriendlyProgress = this.transloco.translate(
            'userInteraction.feature.bomExport.common.progress.finished'
          );
          if (new Date(status?.downloadUrlExpiry) < new Date()) {
            this.downloadBtnTooltip = this.transloco.translate(
              'userInteraction.feature.bomExport.tooltip.downloadExpired'
            );
          }

          break;
        }
        // No default
      }
    }
  }

  private updateProgress(status: BomExportStatus): void {
    switch (status?.progress) {
      case BomExportProgress.STARTED: {
        this.progressBarValue = 0;
        break;
      }
      case BomExportProgress.IN_PROGRESS_1: {
        this.progressBarValue = 10;
        break;
      }
      case BomExportProgress.IN_PROGRESS_2: {
        this.progressBarValue = 20;
        break;
      }
      case BomExportProgress.IN_PROGRESS_3: {
        this.progressBarValue = 30;
        break;
      }
      case BomExportProgress.IN_PROGRESS_4: {
        this.progressBarValue = 40;
        break;
      }
      case BomExportProgress.IN_PROGRESS_5: {
        this.progressBarValue = 50;
        break;
      }
      case BomExportProgress.IN_PROGRESS_6: {
        this.progressBarValue = 60;
        break;
      }
      case BomExportProgress.IN_PROGRESS_7: {
        this.progressBarValue = 80;
        break;
      }
      case BomExportProgress.IN_PROGRESS_8: {
        this.progressBarValue = 90;
        break;
      }
      case BomExportProgress.FINISHED: {
        this.progressBarValue = 100;
        break;
      }
      case BomExportProgress.FAILED: {
        this.progressBarValue = 100;
        break;
      }

      default: {
        this.progressBarValue = 0;
        break;
      }
    }
  }
}
