export enum AppRoutePath {
  BasePath = '',
  SearchPath = 'search',
  ResultsPath = 'results',
  DetailPath = 'detail',
  ComparePath = 'compare',
  PortfolioAnalysisPath = 'portfolio-analysis',
  EmptyStatesPath = 'empty-states',
  LegalPath = 'legal',
}
