<div
  *transloco="let t; read: 'shared.statusBar'"
  [matTooltip]="getTooltip()"
  [matTooltipPosition]="'above'"
  [matTooltipDisabled]="
    selectedNodes?.length >= minCount && selectedNodes?.length <= maxCount
  "
  class="inline-block"
>
  <button
    mat-flat-button
    color="primary"
    class="!mr-4"
    [disabled]="
      selectedNodes?.length < minCount || selectedNodes?.length > maxCount
    "
    (click)="showCompareView()"
  >
    {{ t('compare') }}
  </button>
</div>
