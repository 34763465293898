@if (aggregationModel.isVisible) {
  <div class="flex h-16 items-center justify-end gap-2 font-normal">
    @if (aggregationModel.showFullModel) {
      <div class="font-bold">Average:</div>
    }
    @if (aggregationModel.showFullModel) {
      <div class="font-bold text-high-emphasis">
        {{ aggregationModel.average }}
      </div>
    }
    <div class="font-bold">Count:</div>
    <div class="font-bold text-high-emphasis">{{ aggregationModel.count }}</div>
    @if (aggregationModel.showFullModel) {
      <div class="font-bold">Min:</div>
    }
    @if (aggregationModel.showFullModel) {
      <div class="font-bold text-high-emphasis">
        {{ aggregationModel.min }}
      </div>
    }
    @if (aggregationModel.showFullModel) {
      <div class="font-bold">Max:</div>
    }
    @if (aggregationModel.showFullModel) {
      <div class="font-bold text-high-emphasis">
        {{ aggregationModel.max }}
      </div>
    }
    @if (aggregationModel.showFullModel) {
      <div class="font-bold">Sum:</div>
    }
    @if (aggregationModel.showFullModel) {
      <div class="font-bold text-high-emphasis">
        {{ aggregationModel.sum }}
      </div>
    }
  </div>
}
