<div class="mb-4 flex justify-between text-subtitle-1 text-medium-emphasis">
  <span>Beta Features</span>
  <cdba-beta-feature-badge contentType="general"></cdba-beta-feature-badge>
</div>

<ng-template #highFiveDialog>
  <div
    class="flex h-[60vh] w-[80vh] flex-col items-center justify-center overflow-hidden p-6 text-center"
    (click)="onHighFiveClick()"
  >
    <div class="mb-8 text-h2">
      <span class="font-bold">Thank you</span><br />for helping us with the
      development of<br /><span class="cursor-pointer text-link">CDBA</span>
    </div>
    <div class="animate-bounce text-h2">
      <p>🙌 🙌 🙌</p>
    </div>
  </div>
</ng-template>
