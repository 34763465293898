<div *transloco="let t; read: 'shared.browserSupport.unsupportedBrowser'">
  <h6 mat-dialog-title class="!flex items-center">
    <mat-icon class="mr-2 text-error">warning_amber</mat-icon>
    {{ t('title') }}
  </h6>
  <mat-dialog-content>
    <p [innerHTML]="t('message')" class="whitespace-pre-line"></p>
  </mat-dialog-content>
  <mat-dialog-actions>
    <button mat-button mat-dialog-close color="primary" class="w-full">
      {{ t('confirm') }}
    </button>
  </mat-dialog-actions>
</div>
