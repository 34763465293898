import { BomExportProgress } from './bom-export-status-enum.model';

export interface BomExportStatus {
  requestedBy: string;
  exportType: BomExportType;
  downloadUrl: string;
  downloadUrlExpiry: string;
  progress: BomExportProgress;
  started: string;
  stopped: string;
}

export enum BomExportType {
  SAP = 'SAP',
  PCM = 'PCM',
}
