import { createReducer, on } from '@ngrx/store';

import {
  BomExportProgress,
  BomExportStatus,
} from '@cdba/user-interaction/model/feature/bom-export';
import { BomExportType } from '@cdba/user-interaction/model/feature/bom-export/bom-export-status.model';

import {
  loadInitialBomExportStatuses,
  loadInitialBomExportStatusesFailure,
  loadInitialBomExportStatusesSuccess,
  trackBomExportStatus,
  trackBomExportStatusCompleted,
  trackBomExportStatusFailure,
  updateBomExportStatus,
} from '../../actions';

export interface BomExportFeature {
  loading: boolean;
  errorMessage: string;
  status: BomExportStatus;
}

export interface UserInteractionState {
  feature: {
    sapBomExport: BomExportFeature;
    pcmBomExport: BomExportFeature;
  };
}

export const initialState: UserInteractionState = {
  feature: {
    sapBomExport: {
      loading: false,
      errorMessage: '',
      status: {
        requestedBy: undefined,
        exportType: BomExportType.SAP,
        downloadUrl: undefined,
        downloadUrlExpiry: undefined,
        progress: undefined,
        started: undefined,
        stopped: undefined,
      },
    },
    pcmBomExport: {
      loading: false,
      errorMessage: '',
      status: {
        requestedBy: undefined,
        exportType: BomExportType.PCM,
        downloadUrl: undefined,
        downloadUrlExpiry: undefined,
        progress: undefined,
        started: undefined,
        stopped: undefined,
      },
    },
  },
};

export const userInteractionReducer = createReducer(
  initialState,
  on(
    loadInitialBomExportStatuses,
    (state: UserInteractionState): UserInteractionState => ({
      ...state,
      feature: {
        ...state.feature,
        sapBomExport: {
          ...state.feature.sapBomExport,
          loading: true,
        },
        pcmBomExport: {
          ...state.feature.pcmBomExport,
          loading: true,
        },
      },
    })
  ),
  on(
    loadInitialBomExportStatusesSuccess,
    (state, { statuses }): UserInteractionState => ({
      ...state,
      feature: {
        ...state.feature,
        sapBomExport: {
          ...state.feature.sapBomExport,
          loading: false,
          status:
            statuses.find((s) => s.exportType === BomExportType.SAP) ||
            state.feature.sapBomExport.status,
        },
        pcmBomExport: {
          ...state.feature.pcmBomExport,
          loading: false,
          status:
            statuses.find((s) => s.exportType === BomExportType.PCM) ||
            state.feature.pcmBomExport.status,
        },
      },
    })
  ),
  on(
    loadInitialBomExportStatusesFailure,
    (state, { errorMessage }): UserInteractionState => ({
      ...state,
      feature: {
        ...state.feature,
        sapBomExport: {
          ...state.feature.sapBomExport,
          loading: false,
          errorMessage,
        },
        pcmBomExport: {
          ...state.feature.pcmBomExport,
          loading: false,
          errorMessage,
        },
      },
    })
  ),
  on(
    updateBomExportStatus,
    (state, { currentStatus }): UserInteractionState => ({
      ...state,
      feature: {
        ...state.feature,
        sapBomExport: {
          ...state.feature.sapBomExport,
          loading: false,
          status:
            currentStatus.exportType === BomExportType.SAP
              ? currentStatus
              : state.feature.sapBomExport.status,
        },
        pcmBomExport: {
          ...state.feature.pcmBomExport,
          loading: false,
          status:
            currentStatus.exportType === BomExportType.PCM
              ? currentStatus
              : state.feature.pcmBomExport.status,
        },
      },
    })
  ),
  on(
    trackBomExportStatus,
    (state: UserInteractionState, { exportType }): UserInteractionState => ({
      ...state,
      feature: {
        ...state.feature,
        sapBomExport: {
          ...state.feature.sapBomExport,
          loading:
            exportType === BomExportType.SAP
              ? true
              : state.feature.sapBomExport.loading,
          status: {
            ...state.feature.sapBomExport.status,
            progress:
              exportType === BomExportType.SAP
                ? BomExportProgress.STARTED
                : state.feature.sapBomExport.status.progress,
          },
        },
        pcmBomExport: {
          ...state.feature.pcmBomExport,
          loading:
            exportType === BomExportType.PCM
              ? true
              : state.feature.pcmBomExport.loading,
          status: {
            ...state.feature.pcmBomExport.status,
            progress:
              exportType === BomExportType.PCM
                ? BomExportProgress.STARTED
                : state.feature.pcmBomExport.status.progress,
          },
        },
      },
    })
  ),
  on(
    trackBomExportStatusCompleted,
    (state: UserInteractionState, { exportType }): UserInteractionState => ({
      ...state,
      feature: {
        ...state.feature,
        sapBomExport: {
          ...state.feature.sapBomExport,
          loading:
            exportType === BomExportType.SAP
              ? false
              : state.feature.sapBomExport.loading,
        },
        pcmBomExport: {
          ...state.feature.pcmBomExport,
          loading:
            exportType === BomExportType.PCM
              ? false
              : state.feature.pcmBomExport.loading,
        },
      },
    })
  ),
  on(
    trackBomExportStatusFailure,
    (state, { exportType, errorMessage }): UserInteractionState => ({
      ...state,
      feature: {
        ...state.feature,
        sapBomExport: {
          ...state.feature.sapBomExport,
          loading:
            exportType === BomExportType.SAP
              ? false
              : state.feature.sapBomExport.loading,
          errorMessage,
          status: {
            ...state.feature.sapBomExport.status,
            progress:
              exportType === BomExportType.SAP
                ? BomExportProgress.FAILED
                : state.feature.sapBomExport.status.progress,
          },
        },
        pcmBomExport: {
          ...state.feature.pcmBomExport,
          loading:
            exportType === BomExportType.PCM
              ? false
              : state.feature.pcmBomExport.loading,
          errorMessage,
          status: {
            ...state.feature.pcmBomExport.status,
            progress:
              exportType === BomExportType.PCM
                ? BomExportProgress.FAILED
                : state.feature.pcmBomExport.status.progress,
          },
        },
      },
    })
  )
);
