<ng-container *transloco="let t; read: 'shared.betaFeature.dialog'">
  <h2 mat-dialog-title>
    {{ t('title.main') }}
    <mat-icon mat-dialog-close class="float-right cursor-pointer">
      close
    </mat-icon>
  </h2>
  <div
    mat-dialog-content
    class="whitespace-pre-line"
    [innerHTML]="t('content.' + (modalData.contentType || 'specific'))"
  ></div>
  <div mat-dialog-actions>
    <a [href]="emailTemplate" mat-flat-button color="primary">
      {{ t('action.primary') }}
    </a>
    <button mat-stroked-button mat-dialog-close color="primary">
      {{ t('action.secondary') }}
    </button>
  </div>
</ng-container>
