import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import {
  MatDialogContent,
  MatDialogRef,
  MatDialogTitle,
} from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatTooltipModule } from '@angular/material/tooltip';

import { Subscription } from 'rxjs';

import { PushPipe } from '@ngrx/component';
import { Store } from '@ngrx/store';

import { getUsername } from '@schaeffler/azure-auth';
import { SharedTranslocoModule } from '@schaeffler/transloco';

import { getBomExportFeatures } from '@cdba/core/store';
import { BomExportFeature } from '@cdba/core/store/reducers/user-interaction/user-interaction.reducer';
import { UndefinedAttributeFallbackModule } from '@cdba/shared/pipes';

import { BomExportComponent } from './feature/bom-export/bom-export.component';

@Component({
  templateUrl: './user-interaction-dialog.component.html',
  styleUrls: ['./user-interaction-dialog.component.scss'],
  imports: [
    MatIconModule,
    MatButtonModule,
    MatTooltipModule,
    MatDialogTitle,
    MatDialogContent,
    MatProgressBarModule,
    BomExportComponent,
    SharedTranslocoModule,
    PushPipe,
    UndefinedAttributeFallbackModule,
  ],
})
export class UserInteractionDialogComponent implements OnInit, OnDestroy {
  private readonly dialogRef = inject(
    MatDialogRef<UserInteractionDialogComponent>
  );
  private bomExportsSubscription: Subscription;
  private readonly bomExports$ = this.store.select(getBomExportFeatures);

  username$ = this.store.select(getUsername);

  exports: BomExportFeature[];
  constructor(private readonly store: Store) {}

  onClose(): void {
    this.dialogRef.close();
  }

  ngOnInit(): void {
    this.bomExportsSubscription = this.bomExports$.subscribe(
      (exports: BomExportFeature[]) => {
        this.exports = exports;
      }
    );
  }

  ngOnDestroy(): void {
    if (this.bomExportsSubscription) {
      this.bomExportsSubscription.unsubscribe();
    }
  }
}
