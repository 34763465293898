import { Component, inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { UserInteractionDialogComponent } from './dialog/user-interaction-dialog.component';

@Component({
  selector: 'cdba-user-interaction',
  templateUrl: './user-interaction.component.html',
  standalone: false,
})
export class UserInteractionComponent {
  username: string;

  private readonly dialog = inject(MatDialog);

  openDialog(): void {
    this.dialog.open(UserInteractionDialogComponent);
  }
}
