import { HttpStatusCode } from '@angular/common/http';

import { createAction, props, union } from '@ngrx/store';

import { BomExportStatus } from '@cdba/user-interaction/model/feature/bom-export';
import { BomExportType } from '@cdba/user-interaction/model/feature/bom-export/bom-export-status.model';
import { InteractionType } from '@cdba/user-interaction/model/interaction-type.enum';

import { ReferenceTypeIdentifier } from '../../reducers/search/models/reference-type-identifier.model';

export const showSnackBar = createAction(
  '[UserInteraction] Show Snack Bar',
  props<{ interactionType: InteractionType }>()
);

export const updateBomExportStatus = createAction(
  '[UserInteraction] Update status',
  props<{ currentStatus: BomExportStatus }>()
);

export const loadInitialBomExportStatuses = createAction(
  '[UserInteraction] Load initial BOM Export statuses'
);

export const loadInitialBomExportStatusesSuccess = createAction(
  '[UserInteraction] Load initial BOM Export statuses Success',
  props<{ statuses: BomExportStatus[] }>()
);

export const loadInitialBomExportStatusesFailure = createAction(
  '[UserInteraction] Load initial BOM Export statuses Failure',
  props<{ errorMessage: string }>()
);

export const requestSapBomExport = createAction(
  '[UserInteraction] Request SAP BOM Export',
  props<{ identifiers: ReferenceTypeIdentifier[] }>()
);

export const requestSapBomExportSuccess = createAction(
  '[UserInteraction] Request SAP BOM Export Success'
);

export const requestSapBomExportFailure = createAction(
  '[UserInteraction] Request SAP BOM Export Failure',
  props<{ statusCode: HttpStatusCode; errorMessage: string }>()
);

export const resetSapBomExportStatusTracking = createAction(
  '[UserInteraction] Reset SAP BOM Export tracking'
);

export const requestPcmBomExport = createAction(
  '[UserInteraction] Request PCM BOM Export',
  props<{ identifiers: string[] }>()
);

export const requestPcmBomExportSuccess = createAction(
  '[UserInteraction] Request PCM BOM Export Success'
);

export const requestPcmBomExportFailure = createAction(
  '[UserInteraction] Request PCM BOM Export Failure',
  props<{ statusCode: HttpStatusCode; errorMessage: string }>()
);

export const resetPcmBomExportStatusTracking = createAction(
  '[UserInteraction] Reset PCM BOM Export tracking'
);

export const trackBomExportStatus = createAction(
  '[UserInteraction] Start tracking BOM Export status',
  props<{ exportType: BomExportType }>()
);

export const trackBomExportStatusCompleted = createAction(
  '[UserInteraction] Stop tracking BOM Export status - Completed',
  props<{ exportType: BomExportType }>()
);

export const trackBomExportStatusFailure = createAction(
  '[UserInteraction] Stop tracking BOM Export status - Failure',
  props<{ exportType: BomExportType; errorMessage: string }>()
);

const all = union({
  showSnackBar,
  updateBomExportStatus,
  loadInitialBomExportStatuses,
  loadInitialBomExportStatusesSuccess,
  loadInitialBomExportStatusesFailure,
  resetSapBomExportStatusTracking,
  resetPcmBomExportStatusTracking,
  trackBomExportStatus,
  trackBomExportStatusCompleted,
  trackBomExportStatusFailure,
  requestPcmBomExport,
  requestPcmBomExportSuccess,
  requestPcmBomExportFailure,
  requestSapBomExport,
  requestSapBomExportSuccess,
  requestSapBomExportFailure,
});
export type UserInteractionActions = typeof all;
